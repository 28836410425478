<template>
  <BCard>
    <div class="flex items-center justify-between">
      <div class="m-0 text-2xl font-medium text-black">
        Detail Fullfilment Service
      </div>
      <BButton variant="primary">
        Export
      </BButton>
    </div>
    <BCard
      class="rounded-md p-0 mt-1"
      style="border: 1px solid #e2e2e2"
    >
      <div class="flex justify-between items-center">
        <div class="font-medium m-0 text-black text-lg">
          Mitra Gudang
        </div>
        <div class="flex">
          <BButton
            variant="outline-primary"
            @click="handleRouteDetailWarehouse(dataFullfilment.mitra_id)"
          >
            Lihat Info Gudang
          </BButton>
          <BButton
            variant="outline-primary"
            class="ml-1"
            @click="handlePhone(dataFullfilment.phone_number)"
          >
            Chat
          </BButton>
        </div>
      </div>
      <BRow class="my-1 items-center">
        <BCol lg="auto">
          <div class="flex items-center">
            <BAvatar
              size="lg"
              :src="dataFullfilment.image_logo_url"
            />
            <div class="ml-1">
              <p
                class="m-0 font-medium text-black"
                style="font-size: 16px"
              >
                {{ dataFullfilment.name }}
              </p>
              <p class="m-0">
                {{ DAY_MONTH_YEAR(dataFullfilment.active_date) }}
              </p>
            </div>
          </div>
        </BCol>

      </BRow>
      <BRow class="mt-1 text-black">
        <BCol
          lg="2"
          class="font-semibold"
        >
          Kota :
        </BCol>
        <BCol lg="4">
          {{ dataFullfilment.city_name }}
        </BCol>
        <BCol
          lg="2"
          class="font-semibold"
        >
          Status :
        </BCol>
        <BCol lg="4">
          <span :class="styleAvability(dataFullfilment.availability)">{{ dataFullfilment.availability }}</span>
        </BCol>
      </BRow>
      <BRow class="mt-1 text-black">
        <BCol
          lg="2"
          class="font-semibold"
        >
          Owner :
        </BCol>
        <BCol
          lg="4"
          class="flex"
        >
          <span>{{ dataFullfilment.owner }}</span>
          <img
            src="https://storage.googleapis.com/komerce/assets/verified-shield.svg"
            alt="Komerce"
            class="ml-[5px] w-6 h-6"
          >
        </BCol>
        <BCol
          lg="2"
          class="font-semibold"
        >
          Alamat Gudang :
        </BCol>
        <BCol lg="4">
          {{ dataFullfilment.detail_address }}
        </BCol>
      </BRow>
    </BCard>
    <h4 class="font-medium m-0 text-black mb-1">
      Data Produk
    </h4>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        class="mt-1"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #cell(submission_date)="data">
          <div class="d-grid">
            <h5 class="text-black">
              {{ DAY_MONTH_YEAR(data.item.submission_date) }}
            </h5>
            <span class="text-gray-500">{{ data.item.time }}</span>
          </div>
        </template>
        <template #cell(name)="data">
          <div class="flex items-center">
            <BAvatar :src="data.item.image_logo_url" />
            <div class="ml-1">
              <h5 class="text-black m-0">
                {{ data.item.name }}
              </h5>
              <span class="text-gray-500">{{ data.item.city }}</span>
            </div>
          </div>
        </template>
        <template #cell(owner)="data">
          <div class="text-black">
            {{ data.item.owner }}
          </div>
          <div class="text-black">
            {{ data.item.phone_number }}
          </div>
          <span class="text-gray-500">{{ data.item.phone }}</span>
        </template>
        <template #cell(detail)="data">
          <span
            style="color: #ff6a3a"
            class="underline cursor-pointer"
            @click="handleClick(data.item)"
          >
            Lihat Detail
          </span>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable,
} from 'bootstrap-vue'
import { axiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { fieldDetail } from './config'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
  },
  data() {
    return {
      isLoading: false,
      DAY_MONTH_YEAR,
      dataFullfilment: secureLocalStorage.get('fullfilment'),
      selectMode: 'single',
      items: [],
      fields: fieldDetail,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      const idWarehouse = this.$route.params.id
      const URL = `/v1/warehouse/fulfillment/${idWarehouse}/partner`
      await axiosIns
        .get(URL)
        .then(res => {
          const { data } = res.data
          this.items = data
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    handleClick(data) {
      const { id } = data
      const idWarehouse = this.dataFullfilment.id
      this.$router.push({
        path: `/fulfillment-service/${idWarehouse}/${id}`,
      })
      secureLocalStorage.set('fullfilmentProduct', data)
    },
    styleAvability(value) {
      if (value === 'Penuh') {
        return 'full'
      }
      if (value === 'Tersedia') {
        return 'available'
      }
      return ''
    },
    handlePhone(value) {
      if (value.startsWith('0')) {
        return window.open(`https://wa.me/62${value.substring(1)}`, '_blank')
      }
      return window.open(`https://wa.me/${value}`, '_blank')
    },
    handleRouteDetailWarehouse(idMitra) {
      this.$router.push({ path: `/kompack-rincian-mitra-gudang/${idMitra}/information` })
    },
  },
}
</script>
<style scoped>
.full {
  color: #E31A1A;
  border-radius: 4px;
  padding: 0 10px;
  background: #FFEDED;
}

.available {
  color: #08A0F7;
  border-radius: 4px;
  padding: 0 10px;
  background: #DFF3FF;
}
</style>
